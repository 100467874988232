.productList{
    flex: 4;
}

.productListItem{
    display: flex;
    align-items: center;
}

.productListImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }

.productListEdit{
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #3bb077;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}

.productListDelete{
    color: red;
    cursor: pointer;
}

.editButton {
    margin-left: 4px;
    background-color: #007bff; /* Boja pozadine */
    color: #fff; /* Boja teksta */
    padding: 2px 4px; /* Odstupanje unutar gumba */
    border: none; /* Bez granica */
    border-radius: 4px; /* Zaobljeni rubovi */
    cursor: pointer; /* Kursor u obliku ruke za interakciju */
    text-decoration: none; /* Bez podvlake ispod teksta */
  }
  
  .editButton:hover {
    background-color: #0056b3; /* Boja pozadine pri prelasku mišem */
  }

  .deleteButton {
    margin-left: 4px;
    background-color: #dc3545; /* Crvena boja pozadine za gumb "Izbriši" */
    color: #fff; /* Boja teksta */
    padding: 2px 4px; /* Odstupanje unutar gumba */
    border: none; /* Bez granica */
    border-radius: 4px; /* Zaobljeni rubovi */
    cursor: pointer; /* Kursor u obliku ruke za interakciju */
    text-decoration: none; /* Bez podvlake ispod teksta */
  }
  
  .deleteButton:hover {
    background-color: #c82333; /* Tamnija crvena boja pozadine pri prelasku mišem */
  }

  .viewButton {
    /* margin-left: 4px; */
    background-color: #c9dc35; /* Crvena boja pozadine za gumb "Izbriši" */
    color: #fff; /* Boja teksta */
    padding: 2px 4px; /* Odstupanje unutar gumba */
    border: none; /* Bez granica */
    border-radius: 4px; /* Zaobljeni rubovi */
    cursor: pointer; /* Kursor u obliku ruke za interakciju */
    text-decoration: none; /* Bez podvlake ispod teksta */
  }
  
  .viewButton:hover {
    background-color: #d3eb21; /* Tamnija crvena boja pozadine pri prelasku mišem */
  }
  