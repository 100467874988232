.home{
    flex: 4;
}

.homeWidgets{
    display: flex;
    margin: 20px;
}
.container{
    max-width: 100% !important;
}
.asuFormular{
    padding: 20px;
    width: 100%;
}

.mb-3.formaUnos {
    width: 50% !important;
}